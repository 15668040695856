var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table"},[_c('div',{staticClass:"sizes-table-head"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('size.breast')))])]),_c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('size.waist')))])]),_c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('size.hips')))])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v("eur")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v("USA")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-head-item"},[_c('span',[_vm._v("INTERNATIONAL")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("34")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("4")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("xs")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("80")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("60")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("88")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("36")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("6")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("s")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("84")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("64")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("92")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("38")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("8")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("M")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("88")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("68")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("96")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("40")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("10")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("L")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("92")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("72")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("100")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("42")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("12")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("XL")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("96")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("76")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("104")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("44")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("14")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("XXL")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("100")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("80")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("108")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("46")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("16")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("XXXL")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("104")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("84")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("112")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sizes-table-row"},[_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("48")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("18")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("XXXXL")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("108")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("88")])]),_c('div',{staticClass:"sizes-table-row-item"},[_c('span',[_vm._v("116")])])])
}]

export { render, staticRenderFns }